.consulting-wrap {
  max-width: 720px;
  position: relative;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.consulting-section01 {
  background-image: url(../../../../public/asset/images/promotion/consulting_main.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section01-content {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;
}
.section01-text {
  text-align: center;
  font-family: Pretendard-Black;
  font-size: 32px;
  color: #fff;
}
.comment-box {
  width: 312px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 24px 16px 16px 16px;
  gap: 16px;
  background-color: #171e2660;
}
.comment-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-family: GmarketSansMedium;
  font-size: 14px;
  color: #ffffffa3;
}
.comment-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  font-family: GmarketSansBold;
  font-size: 22px;
  color: #fff;
  text-align: start;
}

.comment {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: GmarketSansLight;
  font-size: 14px;
  line-height: 20px;
  color: #ffffffa3;
  text-align: right;
  margin-right: 30px;
}
.consulting-section02 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.tabBox {
  width: 100%;
  padding: 24px 16px;
  background-color: #fff;
  border-bottom: 1px solid rgba(23, 30, 38, 0.1);
}
.tabBox-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 4px;
  overflow: hidden;
}
.tabButton {
  flex: 1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  cursor: pointer;
}
.section02-content-container {
  width: 100%;
  padding: 80px 0;
}
.personal-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.personal-title-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  margin-bottom: 48px;
  font-family: Pretendard-Bold;
  font-size: 24px;
  color: #171e26;
}
.personal-text {
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 24px;
  color: rgba(23, 30, 38, 0.56);
  text-align: center;
}

.link-to-form-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 0 16px;
}
.link-to-form-card {
  width: 328px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: rgba(247, 248, 249, 1);
  padding: 40px 16px;
}
.link-to-form-card-text-Box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: #171e26;
}
.link-to-form-card-sctipt-box {
  width: 100%;
  text-align: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: rgba(23, 30, 38, 0.56);
  margin-bottom: 24px;
}
.pay-script-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding-bottom: 80px;
  border-bottom: 16px solid rgba(240, 240, 240, 1);
}
.pay-script-box-inner {
  width: 328px;
  background-color: rgba(247, 248, 249, 1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  color: rgba(23, 30, 38, 0.4);
  border-radius: 16px;
}

.pay-script {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.text-dot {
  width: 2px;
  height: 2px;
  border-radius: 50px;
  background-color: rgba(23, 30, 38, 0.4);
  box-sizing: border-box;
  margin-top: 6px;
}
.pay-script p {
  margin: 0;
  flex: 1;
}
.amount-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: rgba(23, 30, 38, 0.78);
}
.amount-info-text-green {
  color: rgba(30, 155, 107, 1);
}
.link-to-form-button-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.link-to-form-button-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 16px;
  cursor: pointer;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: rgba(23, 30, 38, 0.78);
}
.link-to-form-button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: rgba(23, 30, 38, 0.78);
}
.team-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 16px;
  justify-content: center;
  border-bottom: 16px solid rgba(240, 240, 240, 1);
}
.team-title-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding-bottom: 48px;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 24px;
  color: rgba(23, 30, 38, 1);
}
.team-script {
  text-align: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 160%;
  color: rgba(23, 30, 38, 0.78);
}
.team-profile-card-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
}
.team-profile-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.team-profile-comment {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 18px;
  line-height: 26px;
  color: rgba(23, 30, 38, 1);
  text-align: center;
}
.team-profile-record-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.team-profile-name {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 24px;
  color: rgba(23, 30, 38, 1);
  padding-bottom: 4px;
}
.team-profile-name span {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
  color: rgba(23, 30, 38, 0.78);
}
.team-profile-school {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 14px;
  color: rgba(23, 30, 38, 0.78);
}
.team-profile-record {
  font-family: Pretendard-Medium;
  font-size: 12px;
  line-height: 16px;
  color: #000;
}
.team-profile-text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.team-profile-plus {
  font-family: Pretendard-Medium;
  font-size: 18px;
  line-height: 16px;
  color: rgba(23, 30, 38, 0.56);
}
.teatm-profile-plus-text {
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(23, 30, 38, 0.78);
}
.article-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 16px;
}
.article-title-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.article-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 24px;
  color: rgba(23, 30, 38, 0.78);
}
.article-text {
  font-family: Pretendard-Medium;
  font-size: 18px;
  line-height: 26px;
  color: rgba(86, 86, 86, 1);
  text-align: center;
}
.guideandnotice-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  align-items: center;
  padding: 80px 16px;
}
.guideandnotice-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Pretendard-Bold;
  font-size: 18px;
  line-height: 29.7px;
  color: rgba(23, 30, 38, 0.56);
}
.guideandnotice-text {
  width: 100%;
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 20px;
  color: rgba(23, 30, 38, 0.78);
}
.consulting-cta-box {
  width: 100%;
  height: 80px;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 16px;
  background-color: #0b0b0b;
  max-width: inherit;
}
.consulting-cta-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: NanumSquareNeo-Bold;
  font-size: 16px;
  line-height: 20px;
  color: white;
}
.consulting-cta-text-green {
  color: #00ea00;
}
.consulting-cta-button {
  width: 150px;
  height: 52px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #00ea00;
  font-family: NanumSquareNeo-Heavy;
  font-size: 16px;
  line-height: 25px;
  color: #0b0b0b;
}
