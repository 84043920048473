.wrap {
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}

.terms-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 32px;
  padding: 32px 16px;
}
.page-top {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  padding-top: 15px;
}
.page-top p {
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222;
}
.terms-title {
  width: 100%;
  font-family: Pretendard-Bold;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}
.terms-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 32px;
  width: 100%;
}
.terms-privacy-script {
  width: 100%;
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 26px;
}
.terms-anchor-list a {
  width: 100%;
  font-family: Pretendard-Bold;
  font-size: 14px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.terms-content-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.terms-content-item-title {
  font-family: Pretendard-Bold;
  font-size: 16px;
}
.terms-content-item-text {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
}
.supplementary-provisions {
  font-family: Pretendard-Bold;
  font-size: 16px;
}
.version-select {
  width: 140px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 22px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(23, 30, 38, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-non-border {
  width: 96%;
  border: none;
  color: #616161;
  outline: none;
  font-family: Pretendard-Medium;
  font-size: 16px;
}
