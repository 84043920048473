.container {
  max-width: 720px;
  background-color: #f5f5f5;
  position: relative;
}
.page-top-myinfo {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 10;
  max-width: inherit;
}
.page-top-myinfo p {
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222;
}
.link-button {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: rgba(55, 56, 60, 0.61);
}
.content-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 56px;
}
.basic-info-box {
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #fff;
}
.image-box {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-inner {
  width: 84px;
  height: 84px;
  position: relative;
}
.profile {
  width: 84px;
  height: 84px;
  background-color: rgba(112, 115, 124, 0.16);
  border: none;
  border-radius: 50%;
}
.pencil {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
}
.info-input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.section-title {
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: #222;
}
.info_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.label {
  font-family: Pretendard-Regular;
  font-size: 14px;
  color: #616161;
}
.label span {
  color: #00b452;
}
.info-input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  padding-left: 14px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #616161;
}
.ask-change-phone {
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: rgba(55, 56, 60, 0.61);
}
.ask-change-phone span {
  text-decoration: underline;
}
.extra-info-box {
  width: 100%;
  padding: 32px 16px 126px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fff;
}

.info-select-box {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-non-border {
  width: 96%;
  border: none;
  color: #616161;
  outline: none;
  font-family: Pretendard-Medium;
  font-size: 16px;
  background-color: transparent;
}
.apply-button-box {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  padding: 16px;
  max-width: inherit;
}
.apply-button {
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
}
