.NoLoginBox {
  width: 100%;
  height: 216px;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
}

.NoLogin_img {
  width: 42px;
  height: 42px;
}

.NoLogin_text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.NoLogin_text_1 {
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
}

.NoLogin_text_2 {
  text-align: center;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.NoLogin_Button {
  padding: 8px 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #1e9b6b;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #1e9b6b;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 26.4px;
  word-wrap: break-word;
  text-align: center;
  cursor: pointer;
}
