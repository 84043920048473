.cta-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  text-decoration: none;
  max-width: inherit;
  padding: 0 16px;
}

.cta-inner {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.cta-text-box {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
}
.cta-text {
  font-family: 'NanumSquareNeo-Bold';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #fff;
}
.cta-text-green {
  font-family: 'NanumSquareNeo-ExtraBold';
  color: #00ea00;
}
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: #00ea00;
  border-radius: 8px;
  width: 150px;
  height: 52px;
}
.action-btn-a {
  text-decoration: none;
  color: #0b0b0b;
  font-family: 'NanumSquareNeo-Heavy';
  font-size: 16px;
  line-height: 25px;
  white-space: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .cta-wrap {
    height: 120px;
  }
  .cta-inner {
    width: 1200px;
    padding: 0;
  }
  .cta-text-box {
    height: 50px;
  }
  .cta-text {
    font-size: 20px;
    line-height: 29px;
  }
  .action-btn {
    height: 56px;
    padding: 15.5px 24px;
    width: fit-content;
  }
  .action-btn-a {
    font-size: 24px;
    white-space: nowrap;
    text-align: center;
    flex-direction: row;
    gap: 8px;
  }
}
