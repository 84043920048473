@font-face {
  font-family: 'Pretendard-Thin';
  src: url('../public/asset/fonts/Pretendard-Thin.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-ExtraLight';
  src: url('../public/asset/fonts/Pretendard-ExtraLight.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Light';
  src: url('../public/asset/fonts/Pretendard-Light.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('../public/asset/fonts/Pretendard-Regular.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Medium';
  src: url('../public/asset/fonts/Pretendard-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('../public/asset/fonts/Pretendard-SemiBold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('../public/asset/fonts/Pretendard-Bold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-ExtraBold';
  src: url('../public/asset/fonts/Pretendard-ExtraBold.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Black';
  src: url('../public/asset/fonts/Pretendard-Black.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'SpoqaHanSansNeoThin';
  font-weight: 100;
  font-style: normal;
  src: url('../public/asset/fonts/SpoqaHanSansNeoThin.ttf') format('truetype');
}
@font-face {
  font-family: 'SpoqaHanSansNeoLight';
  font-weight: 300;
  font-style: normal;
  src: url('../public/asset/fonts/SpoqaHanSansNeoLight.ttf') format('truetype');
}
@font-face {
  font-family: 'SpoqaHanSansNeoRegular';
  font-weight: 400;
  font-style: normal;
  src: url('../public/asset/fonts/SpoqaHanSansNeoRegular.ttf')
    format('truetype');
}
@font-face {
  font-family: 'SpoqaHanSansNeoMedium';
  font-weight: 500;
  font-style: normal;
  src: url('../public/asset/fonts/SpoqaHanSansNeoMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'SpoQaHanSansNeoBold';
  font-weight: 700;
  font-style: normal;
  src: url('../public/asset/fonts/SpoqaHanSansNeoBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquareNeo-Light';
  font-weight: '350';
  src: url('../public/asset/fonts/NanumSquareNeoOTF-Lt.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-Regular';
  font-weight: '400';
  src: url('../public/asset/fonts/NanumSquareNeoOTF-Rg.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-Bold';
  font-weight: '700';
  src: url('../public/asset/fonts/NanumSquareNeoOTF-Bd.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-ExtraBold';
  font-weight: '800';
  src: url('../public/asset/fonts/NanumSquareNeoOTF-Eb.otf') format('OpenType');
}
@font-face {
  font-family: 'NanumSquareNeo-Heavy';
  font-weight: '900';
  src: url('../public/asset/fonts/NanumSquareNeoOTF-Hv.otf') format('OpenType');
}

@font-face {
  font-family: 'GmarketSansLight';
  font-weight: '300';
  src: url('../public/asset/fonts/GmarketSansTTFLight.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansMedium';
  font-weight: '500';
  src: url('../public/asset/fonts/GmarketSansTTFMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'GmarketSansBold';
  font-weight: '700';
  src: url('../public/asset/fonts/GmarketSansTTFBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-Thin';
  font-weight: '100';
  src: url('../public/asset/fonts/Paperlogy-1Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-ExtraLight';
  font-weight: '200';
  src: url('../public/asset/fonts/Paperlogy-2ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-Light';
  font-weight: '300';
  src: url('../public/asset/fonts/Paperlogy-3Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-Regular';
  font-weight: '400';
  src: url('../public/asset/fonts/Paperlogy-4Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-Medium';
  font-weight: '500';
  src: url('../public/asset/fonts/Paperlogy-5Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-SemiBold';
  font-weight: '600';
  src: url('../public/asset/fonts/Paperlogy-6SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-Bold';
  font-weight: '700';
  src: url('../public/asset/fonts/Paperlogy-7Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-ExtraBold';
  font-weight: '800';
  src: url('../public/asset/fonts/Paperlogy-8ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Paperlogy-Black';
  font-weight: '900';
  src: url('../public/asset/fonts/Paperlogy-9Black.ttf') format('truetype');
}
