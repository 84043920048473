.mypoint-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: #09ce7d;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
  }
  .mypoint-box-left {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: Pretendard-Bold;
  }
  .total-point {
    font-size: 20px;
  }
  .loading-icon {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  