.PopularBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 236px;
  box-sizing: border-box;
  gap: 16px;
}

.PopularBox_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
}

.PopularBox_top_bar_title {
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.PopularBox_top_bar_date {
  color: rgba(55, 56, 60, 0.28);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
}

.PopularSearchListBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.PopularSearchList {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.PopularItem {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  cursor: pointer;
}

.PopularItem_Data {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.PopularItem_Data_No {
  width: 18px;
  text-align: center;
  color: #616161;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}
.PopularItem_Data_Region {
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.PopularItem_State {
  display: flex;
  font-size: 9px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 12px;
  word-wrap: break-word;
  width: 30px;
  justify-content: flex-end;
}

.PopularItem_State_Up {
  color: #ec4f4f;
}
.PopularItem_State_Down {
  color: #358cff;
}
.PopularItem_State_Stay {
  color: rgba(55, 56, 60, 0.16);
}
.PopularItem_State_New {
  color: #ec4f4f;
}
