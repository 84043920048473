.box-table {
  border: none;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-family: Pretendard-Medium;
}

.box-table thead {
  background-color: #f7f8f9;
}

.box-table thead tr th {
  padding: 4px 0px;
  border: none;
  color: #171e268f;
  flex: 1;
}
.box-table tr td {
  padding: 4px 0px;
  border: none;
  flex: 1;
}

.box-table .padding-8 {
  padding: 8px !important;
}

.box-table .font-bold {
  font-family: Pretendard-Bold !important;
}

.box_date_selector {
  position: fixed;
  align-items: center;
  justify-content: center;
  width: '100%';
  height: '100%';
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 30;
  background: #00000070;
}

.box_date_selector .top {
  position: fixed;
  background: #ffffff;
  border-top: 0.1px solid #171e2666;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  bottom: 0;
  z-index: 30;
}

.box_date_selector .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  font-family: Pretendard-Bold;
  font-size: 16px;
  line-height: 24px;
  color: #171e26;
}
.box-table.box-table-center {
  text-align: center;
}
.box-table.box-table-center thead tr th {
  height: 36px;
  width: 109.33px;
  border-bottom: 1px solid #171e261a;
  border-top: 1px solid #171e261a;
}
.box-table.box-table-center tbody tr td {
  width: 109.33px;
  border-bottom: 1px solid #171e261a;
  height: 36px;
}

@media (min-width: 1024px) {
  .box_date_selector .top {
    left: 50%;
    bottom: 0%;
    padding: 16px 16px 48px 16px;
    height: 419px;
    transform: translate(-50%, -50%);
    width: 360px;
    /* top: '57%', */
  }
  .box_date_selector .picker {
    margin-top: 70px;
    /*margin-x-54 */
    margin-inline: 54px;
  }

  .box_date_selector .bottom {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
    margin-top: 80px;
    gap: 8px;
  }

  .box_date_selector .bottom .ok {
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    font-family: Pretendard-Bold;
    width: 100%;
    background-color: #1e9b6b;
    border-radius: 8px;
    padding: 14px 16px;
    cursor: pointer;
    user-select: none;
    /* .flex-center  */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box_date_selector .bottom .cancel {
    font-size: 18px;
    /* color-blue-gray-600 */
    color: #5e7188;
    line-height: 26px;
    font-family: Pretendard-Bold;
    width: 100%;
    background-color: #ffffff;
    /* border-E5EAF0 */
    border: 1px solid #e5eaf0;

    border-radius: 8px;
    padding: 14px 16px;
    /* hover */
    cursor: pointer;
    user-select: none;
    /* .flex-center  */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .box_date_selector .mobile {
    display: none;
  } */
}

@media (max-width: 1024px) {
  .tablet-table {
    padding: 0;
  }
  .box_date_selector .pc {
    display: none;
  }

  .box_date_selector .top {
    left: 0;
    right: 0;
    height: 320px;
  }

  .box_date_selector .content {
    padding-inline: 70px;
    padding-top: 32px;
    /* padding-bottom: 24px; */
  }

  .box_date_selector .bottom {
    /* flex-row */
    display: flex;
    flex-direction: row;
    /* center-y */
    align-items: center;
    font-family: Pretendard-Bold;
    font-size: 18px;
    line-height: 26px;
    /* hover */
    cursor: pointer;
    user-select: none;
    padding: 14px 0;
  }

  .box_date_selector .div {
    width: 0.62px;
    height: 32px;
    background: #171e2666;
  }
}

.box_card {
  /* flex-column */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-y-13   */
  padding: 13px 0;
}

.box_card .title {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  /* color-text-tertiary */
  color: #171e268f;
}

.box_card .text {
  font-family: Pretendard-Bold;
  font-size: 18px;
  line-height: 26px;
  /* color-text-secondary */
  color: rgba(23, 30, 38, 0.78);
}

.button_select {
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  /* hover */
  cursor: pointer;
  user-select: none;
  border: none;
  background: #f0f0f0;
}

.button_select_selected {
  padding: 8px;
  border-radius: 8px;
  justify-content: center;
  /* hover */
  cursor: pointer;
  user-select: none;
  border: 1px solid #1e9b6b;
  background: #ffffff;
  filter: drop-shadow(2px 4px 0 #1e986b10);
}

.buttion_text {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-family: Pretendard-Medium;
  color: #171e268f;
}

.buttion_text2 {
  text-align: center;
  color: #171e268f !important;
}

.buttion_text_selected2 {
  text-align: center;
  color: #ffffff !important;
}

.buttion_text_selected {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-family: Pretendard-Bold;
  color: #1e9b6b;
}

.row_table {
  /* flex-row */
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  font-family: Pretendard-Medium;
}

.row_table_font_bold {
  font-family: Pretendard-Bold !important;
}

.row_table_bottom {
  padding-bottom: 8px;
  /* border-b-1-171E2610 */
  border-bottom: 1px solid #171e2610;
}

.custom_box-table {
  border: none;
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-family: Pretendard;
}

.custom_box-table thead {
  background-color: #f7f8f9;
}

.custom_box-table thead tr th {
  padding: 4px 8px;
  border: none;
  color: #171e26c7;
  font-weight: 500;
}
.custom_box-table tr td {
  padding: 4px 8px;
  border: none;
  font-weight: 500;
  color: #171e26c7;
}

.custom_box-table .font-bold {
  font-family: Pretendard-Bold !important;
}

.custom_box-table thead tr th .special-color {
  color: #171e26;
  font-weight: 700;
  text-align: center;
  justify-content: center;
}
