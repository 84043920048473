* {
  box-sizing: border-box;
  margin: 0;
}

body {
  max-width: 720px;
  height: 100dvh;
  margin: auto;
  box-shadow: 0 0 20px hsla(0, 0%, 51%, 0.15);
  font-family: 'Pretendard-Thin', 'Pretendard-ExtraLight', 'Pretendard-Light',
    'Pretendard-Regular', 'Pretendard-Medium', 'Pretendard-SemiBold',
    'Pretendard-Bold', 'Pretendard-ExtraBold', 'Pretendard-Black', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
