.LatestSearchList {
  width: 100%;
  height: 72px;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
}
.LatestSearchList_top_bar {
  align-self: stretch;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.LatestSearchList_top_bar_title {
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  display: inline-flex;
  justify-self: flex-start;
}

.LatestSearchList_top_bar_delete_text {
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
}

.LatestSearchList_content_box_wrapper {
  width: 100%;
  height: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
  padding-bottom: 4px;
  /* -webkit-overflow-scrolling: touch;
  touch-action: pan-x; */
}

/* WebKit 브라우저 전용 */
.LatestSearchList_content_box_wrapper::-webkit-scrollbar {
  width: 8px; /* 스크롤바 너비 */
}

.LatestSearchList_content_box_wrapper::-webkit-scrollbar-track {
  background: white; /* 트랙 색상 */
}

.LatestSearchList_content_box_wrapper::-webkit-scrollbar-thumb {
  background: white; /* 스크롤바 색상 */
}

.LatestSearchList_content_box_wrapper::-webkit-scrollbar-thumb:hover {
  background: white; /* hover 시 색상 */
}

.LatestSearchList_content_box {
  width: fit-content;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(112, 115, 124, 0.08);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.LatestSearchList_content_box_text {
  height: 20px;
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
}

.LatestSearchList_content_box_img {
  cursor: pointer;
  width: 16px;
  height: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
}
