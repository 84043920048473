.SearchContainer {
  padding: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100vh;
  gap: 32px;
  margin-top: 56px;
}

.SearchBox {
  margin-bottom: 16px;
}

.LatestSearchBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 146px;
  padding-bottom: 16px;
  overflow: hidden;
}
.LatestSearchBox_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
}

.LatestCheckBox {
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 208px;
  padding-bottom: 16px;
}

.LatestCheckBox_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
}

/* 
.LatestSearchBox_top_bar_title {
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500px;
  line-height: 20px;
  word-wrap: break-word;
}

.LatestCheckBox_top_bar_title {
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500px;
  line-height: 20px;
  word-wrap: break-word;
} */
