.w-fit-content {
  width: fit-content;
}
.w-100per {
  width: 100%;
}

.w-50per {
  width: 50%;
}

@media (min-width: 1024px) {
  .browser-w-360 {
    width: 360px;
  }
}
.padding-8-16 {
  padding: 8px 16px;
}
.padding-12-12-12-8 {
  padding: 12px 12px 12px 8px;
}
.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}
.padding-x-16 {
  padding: 0px 16px;
}

.padding-x-16 {
  padding: 0 16px;
}
.padding-6 {
  padding: 6px;
}
.padding-2-8 {
  padding: 2px 8px;
}

.padding-6-10 {
  padding: 6px 10px;
}
.padding-16-16-8-16 {
  padding: 16px 16px 8px 16px;
}
.padding-12-16 {
  padding: 12px 16px;
}
.padding-24-16-8-16 {
  padding: 24px 16px 8px 16px;
}
.padding-b-16 {
  padding-bottom: 16px;
}
.padding-b-100 {
  padding-bottom: 100px;
}

.h-100per {
  height: 100%;
}
.h-100vh {
  height: 100vh;
}
@media (min-width: 1024px) {
  .browser-h-100per {
    height: 100%;
  }
}
.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-6 {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-32 {
  gap: 32px;
}

.gap-40 {
  gap: 40px;
}
.bg-white {
  background-color: white;
}
.bg-00000050 {
  background-color: #00000050;
}
.bg-FFFFFF {
  background-color: #ffffff;
}
/*new*/

.bg-primary-600 {
  background-color: #166f4d;
}

.bg-blue-gray-100 {
  background-color: #e5eaf0;
}

.bg-blue-gray-800 {
  background-color: #2a3746;
}
.bg-light-gray {
  background-color: #f7f8f9;
}
.bg-light-gray-200 {
  background-color: #f0f0f0;
}
@media (min-width: 1024px) {
  .browser-bg-FFFFFF {
    background-color: #ffffff;
  }
}

.nowrap {
  white-space: nowrap;
}
.color-white {
  color: white;
}

.color-FFFFFF {
  color: #ffffff;
}
/*new*/
.color-disabled {
  color: rgba(23, 30, 38, 0.4);
}
.color-text-primary {
  color: #171e26;
}
.color-171E2640 {
  color: #171e2640;
}
.color-text-secondary {
  color: #616161;
  /* color: #171e26c7; */
  /* color: #171e2678; */
}
.color-text-tertiary {
  /* color: #171e2656; */
  color: #171e268f;
}
.font-bold {
  font-family: Pretendard-Bold;
}
.font-medium {
  font-family: Pretendard-Medium;
}

.f-size-11 {
  font-size: 0.6875rem;
}

.f-size-12 {
  font-size: 0.75rem;
}

.f-size-14 {
  font-size: 0.875rem;
}

.f-size-16 {
  font-size: 1rem;
}

.f-size-20 {
  font-size: 1.25rem;
}

/* ---------------------------------- */
.line-h-12 {
  line-height: 12px;
}
.line-h-14 {
  line-height: 14px;
}
.line-h-16 {
  line-height: 16px;
}
.line-h-20 {
  line-height: 20px;
}

.line-h-24 {
  line-height: 24px;
}

.line-h-29 {
  line-height: 29px;
}
@media (min-width: 1024px) {
  /* ---------------------------------- */
}

@media (min-width: 1920px) {
  /* .browser-; */
}

.hover {
  cursor: pointer;
  user-select: none;
}

/* .hidden_policy_dropdown_item:hover {
  background: #eaf8f2;
  color: #09ce7d;
} */

.hover:hover {
  opacity: 70%;
}
.hover:active {
  opacity: 100%;
  filter: brightness(80%);
}
input::placeholder {
  color: #171e2640;
  font-family: Pretendard-Medium;
}

.report_result_box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 4px 10px
    rgba(
      0.16470588743686676,
      0.21568627655506134,
      0.27450981736183167,
      0.07999999821186066
    );
}
.fit-content {
  width: fit-content;
}

.border-b-171E26 {
  border-bottom: 1px solid #171e2610;
}

.background {
  background: url('') no-repeat left top;
  background-size: cover;
  background-position: right;
  background-color: #1e9b6b;
}

/* .tool-tip:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: #3d5066;
  border-bottom: 0;
  margin-left: -7px;
  margin-bottom: -7px;
} */

.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.bottom-0 {
  bottom: 0;
}

.of-y-auto {
  overflow-y: auto;
}
.border-radius-t-l-16 {
  border-top-left-radius: 16px;
}
.border-radius-t-r-16 {
  border-top-right-radius: 16px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.no-wrap {
  white-space: nowrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.center-y {
  align-items: center;
}

.center-x {
  justify-content: center;
}

.end-x {
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}

input:focus {
  outline: none;
}
.border-radius-8 {
  border-radius: 8px;
}

.border-radius-100 {
  border-radius: 100px;
}

.border-1-e2e2e2 {
  border: 1px solid #e2e2e2;
}
.relative {
  position: relative;
}
/* .background-card {
  background: linear-gradient(
      to bottom,
      rgba(247, 248, 249, 1),
      rgba(221, 239, 233, 1)
    ),
    ''
  background-size: contain;
  background-position: center center;
  height: 180px;

  width: 100%;
} */

.border-1-171E26 {
  border: 1px solid #171e2610;
}
.border-radius-6 {
  border-radius: 6px;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100px;
  }
}
.house {
  display: none;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes fadeout {
  /* 효과를 동작시간 동안 0 ~ 1까지 */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*font*/
/*font*/

.tablet {
  display: none;
}

.none {
  display: none;
}

@media (max-width: 1023px) {
  .tablet {
    display: flex;
  }

  .house {
    display: block;
    width: 60px;
    height: 60px;
  }

  /* font */

  /* font */
}

@media (min-width: 1024px) {
  .browser-fixed {
    position: fixed;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* ::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  } */

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c1c1c1;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  /*font*/
}
@keyframes joinbubble {
  0% {
    top: 0;
  }
  100% {
    top: -4px;
  }
}

.page {
  height: 21cm;
  width: 29.7cm;
  overflow: hidden;
}

@page {
  size: A4 landscape;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    min-height: 21cm;
    width: 29.7cm;
  }
}

.bg-light-gray-100 {
  background-color: #f7f8f9;
}

.bg-primary-500 {
  background-color: #1e9b6b;
}

.border-radius-100per {
  border-radius: 100%;
}

.of-hidden {
  overflow: hidden;
}
.text-center {
  text-align: center;
}
