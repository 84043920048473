.NoSearchResultBox {
  width: 100%;
  height: 158px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  padding-top: 24px;
  padding-bottom: 24px;
}

.NoResultBox {
  width: 100%;
  height: 146px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
}

.NoCheckResultBox {
  width: 100%;
  height: 208px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
}

.NoResultBox_top_bar {
  width: 100%;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
}

.NoResultBox_top_bar_text {
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
}

.NoResultBox_top_bar_delete {
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

/* .NoResultBox {
  width: 100%;
  height: 146px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  box-sizing: border-box;
  overflow: hidden;
} */

.NoResultBox_img {
  width: 42px;
  height: 42px;
}

.NoResultBox_text {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}
.NoResultBox_text_1 {
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
}

.NoResultBox_text_2 {
  white-space: nowrap;
  text-align: center;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  box-sizing: border-box;
}
