.info-slider {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  height: 350px;
  margin-bottom: 64px;
  overflow-x: hidden;
  padding: 0 1px 0 16px;
}
.info-item {
  box-sizing: border-box;
  width: 280px;
  height: 340px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
}
.image-box {
  width: 280px;
  height: 180px;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-box1 {
  border-radius: 16px 16px 0px 0px;
  padding: 0 25px 0 4px;
  background-image: url(../../../../../public/asset/images/promotion/info-01.jpg);
  background-size: cover;
  background-position: center;
}
.image-box2 {
  border-radius: 16px 16px 0px 0px;
  background-image: url(../../../../../public/asset/images/promotion/info-02-1.png);
  background-size: cover;
  background-position: center;
}
.image-box3 {
  border-radius: 16px 16px 0px 0px;
  background-image: url(../../../../../public/asset/images/promotion/info-03.png);
  background-size: cover;
  background-position: center;
}
.image-box4 {
  border-radius: 16px 16px 0px 0px;
  background-image: url(../../../../../public/asset/images/promotion/info-04.jpg);
  background-size: cover;
  background-position: center;
}

.info-text-box {
  display: flex;
  height: 160px;
  width: 280px;
  padding: 20px 6px 20px 14px;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}
.info-text-box a {
  text-decoration: none;
  color: #000;
}

.info-text-box li {
  color: #000;
  line-height: 18px;
  width: 250px;
}
.nh-logo {
  height: 80px;
}
.sopoong-logo {
  height: 30px;
}

@media (min-width: 1024px) {
  .info-slider {
    max-width: 1200px;
    margin-bottom: 0;
  }
}
