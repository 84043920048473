.fade-in-up {
  animation: fadeInUp 1s ease-out;
}
.fade-in-down {
  animation: fadeInDown 1s ease-out;
}

.of-scroll::-webkit-scrollbar {
  display: none;
}

.scroll-container::-webkit-scrollbar {
  height: 6px; /* 가로 스크롤바의 높이를 얇게 조정 */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* 스크롤바 색상 설정 */
  border-radius: 3px; /* 스크롤바의 모서리 둥글게 */
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent; /* 스크롤바 트랙 투명하게 설정 */
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100px;
  }
}
