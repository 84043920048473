.bg-FFFFFF {
  background-color: #ffffff;
}
.bg-primary-100 {
  background-color: #eaf8f2;
}
@media (min-width: 1024px) {
  .browser-bg-FFFFFF {
    background-color: #ffffff;
  }
}

.nowrap {
  white-space: nowrap;
}
.color-text-primary {
  color: #171e26;
}
.color-text-secondary {
  color: rgba(23, 30, 38, 0.78);
}
.color-dark-secondary {
  color: #171e26c7;
}
.color-dark-secondary {
  color: #171e26c7;
}
.color-text-tertiary {
  /* color: #171e2656; */
  color: #171e268f;
}
.font-bold {
  font-family: Pretendard-Bold;
}
.font-medium {
  font-family: Pretendard-Medium;
}

.f-size-11 {
  font-size: 0.6875rem;
}

.f-size-12 {
  font-size: 0.75rem;
}

.f-size-13 {
  font-size: 0.8125rem;
}

.f-size-14 {
  font-size: 0.875rem;
}

.f-size-18 {
  font-size: 1.125rem;
}

/* ---------------------------------- */
.line-h-20 {
  line-height: 20px;
}

.line-h-24 {
  line-height: 24px;
}

.line-h-26 {
  line-height: 26px;
}
.m-b2-14-m {
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
}
.m-c2-11-m {
  font-family: Pretendard-Medium;
  font-size: 11px;
  line-height: 12px;
}
@media (min-width: 1024px) {
  /* ---------------------------------- */
}

@media (min-width: 1920px) {
  /* .browser-; */
}

.gap-2 {
  gap: 2px;
}

.gap-5 {
  gap: 5px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-16 {
  gap: 16px;
}
.h-8 {
  height: 8px;
}
.padding-8-16 {
  padding: 8px 16px;
}
.padding-12 {
  padding: 12px;
}
.padding-6-16 {
  padding: 6px 16px;
}
.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}

.padding-24-16 {
  padding: 24px 16px;
}

.padding-24-16-8 {
  padding: 24px 16px 8px;
}
.padding-2-8 {
  padding: 2px 8px;
}
.padding-b-24 {
  padding-bottom: 24px;
}
.padding-6-16 {
  padding: 6px 16px;
}
.w-8 {
  width: 8px;
}
.w-100per {
  width: 100%;
}

.hover {
  cursor: pointer;
  user-select: none;
}

.hover:hover {
  opacity: 70%;
}
.hover:active {
  opacity: 100%;
  filter: brightness(80%);
}
.z-9999 {
  z-index: 9999;
}
.border-radius-999 {
  border-radius: 999px;
}

.tooltip {
  opacity: 0;
  transform: translateY(-10px);
  transition:
    opacity 1s,
    transform 1s;
}

.transparent {
  background: transparent;
}
.z-999 {
  z-index: 999;
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.of-x-auto {
  overflow-x: auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.no-wrap {
  white-space: nowrap;
}

.space-between {
  justify-content: space-between;
}

.center-y {
  align-items: center;
}

.center-x {
  justify-content: center;
}
.flex {
  display: flex;
}
.border-171E26 {
  border: 1px solid #171e2610;
}

.border-radius {
  border-radius: 12px;
}
.border-radius-8 {
  border-radius: 8px;
}

.border-radius-100 {
  border-radius: 100px;
}

.border-1-e2e2e2 {
  border: 1px solid #e2e2e2;
}
.relative {
  position: relative;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100px;
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes fadeout {
  /* 효과를 동작시간 동안 0 ~ 1까지 */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.pointer {
  cursor: pointer;
}

/*font*/

.bold {
  font-family: SpoQaHanSansNeoBold;
}
/*font*/

.none {
  display: none;
}

@media (max-width: 1023px) {
  /* font */

  /* font */
}

@media (min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    /* background-color: #f0f0f0; */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c1c1c1;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  /*font*/
}
@keyframes joinbubble {
  0% {
    top: 0;
  }
  100% {
    top: -4px;
  }
}

@page {
  size: A4 landscape;
  margin: 0;
}

.tota {
  background: #ffffff;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.title {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-body-l-16-bold-font-size, 16px);
  line-height: var(--mo-body-l-16-bold-line-height, 150%);
  font-weight: var(--mo-body-l-16-bold-font-weight, 700);
  position: relative;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-body-l-16-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-l-16-medium-font-size, 16px);
  line-height: var(--mo-body-l-16-medium-line-height, 150%);
  font-weight: var(--mo-body-l-16-medium-font-weight, 500);
  position: relative;
}

.text2 {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-l-16-medium-font-size, 16px);
  line-height: var(--mo-body-l-16-medium-line-height, 150%);
  font-weight: var(--mo-body-l-16-medium-font-weight, 500);
  position: relative;
}

.tota {
  background: #ffffff;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 360px;
  position: relative;
}
.frame-26083552 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px 16px 16px 16px;
  display: block;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.mask-group {
  align-self: stretch;
  flex-shrink: 0;
  height: 268px;
  position: static;
}

.frame-2609057 {
  /* background: #ffffff; */
  opacity: 0.9900000095367432;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
table {
  table-layout: fixed;
  width: 100%;
}

/* table td:nth-child(3) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.frame-2609226 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2609224 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2609205 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  height: 268px;
  position: relative;
}
.text {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-caption-l-12-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-caption-l-12-medium-font-size, 12px);
  line-height: var(--mo-caption-l-12-medium-line-height, 14px);
  font-weight: var(--mo-caption-l-12-medium-font-weight, 500);
  position: relative;
}
.text2 {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-caption-l-12-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-caption-l-12-bold-font-size, 12px);
  line-height: var(--mo-caption-l-12-bold-line-height, 14px);
  font-weight: var(--mo-caption-l-12-bold-font-weight, 700);
  position: relative;
}
.frame-2609204 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2609203 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 283px;
  position: relative;
}
.rectangle-2948 {
  background: var(--alias-color-text-white-bluegray-100, #eff2f5);
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  flex: 1;
  height: 134px;
  position: relative;
}
.rectangle-2949 {
  background: var(--alias-color-text-white-bluegray-100, #eff2f5);
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  flex: 1;
  height: 134px;
  position: relative;
}
.frame-2609237 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 283px;
  position: relative;
}
.frame-2609225 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 281px;
  position: relative;
}
.frame-2609206 {
  display: flex;
  align-items: baseline;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 16%;
}
.frame-2609241 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
}

/* Detalil7 */
._3-km {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: center;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.frame-2609304,
.frame-2609304 * {
  box-sizing: border-box;
}
.frame-2609304 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 213px;
  position: relative;
}
.tota,
.tota * {
  box-sizing: border-box;
}
.tota {
  background: #ffffff;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
}
.tota .frame-2609336 {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.tota .title {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-l-16-bold-font-family,
    'Pretendard-Bold',
    sans-serif
  );
  font-size: var(--mo-body-l-16-bold-font-size, 16px);
  line-height: var(--mo-body-l-16-bold-line-height, 150%);
  font-weight: var(--mo-body-l-16-bold-font-weight, 700);
  position: relative;
}
.tota ._3-km {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: center;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}

/* Detalil8 */
.frame-2609340,
.frame-2609340 * {
  box-sizing: border-box;
}
.frame-2609340 {
  background: #fff;
  padding: 0px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: left;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-2609304 {
  background: #ffffff;
  opacity: 0.9900000095367432;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.frame-2609338 {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: var(--alias-color-line-border, rgba(23, 30, 38, 0.1));
  border-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 328px;
  position: relative;
}
.monthly {
  border-radius: 16px 16px 0px 0px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 96px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 328px;
  position: relative;
}
.frame-2608953 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
}
.arrow {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: visible;
}
.frame-26089532 {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-2608953 img {
  cursor: pointer;
}
._2023-122 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-body-l-16-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-l-16-medium-font-size, 16px);
  line-height: var(--mo-body-l-16-medium-line-height, 150%);
  font-weight: var(--mo-body-l-16-medium-font-weight, 500);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.frame-2608355 {
  border-radius: 0px 0px 16px 16px;
  padding: 40px 16px 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 328px;
  position: relative;
  align-self: stretch;
}
.div {
  color: var(--alias-color-text-dark-text-secondary, rgba(23, 30, 38, 0.78));
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}

.frame-2608355,
.frame-2608355 * {
  box-sizing: border-box;
}
.frame-2608355 {
  border-radius: 0px 0px 16px 16px;
  padding: 4px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-2609341 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.text {
  color: var(--alias-color-text-dark-text-tertiary, rgba(23, 30, 38, 0.56));
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.text2 {
  color: var(--alias-color-text-dark-text-primary, #171e26);
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.text3 {
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.text4 {
  text-align: left;
  font-family: var(
    --mo-body-s-14-medium-font-family,
    'Pretendard-Medium',
    sans-serif
  );
  font-size: var(--mo-body-s-14-medium-font-size, 14px);
  line-height: var(--mo-body-s-14-medium-line-height, 20px);
  font-weight: var(--mo-body-s-14-medium-font-weight, 500);
  position: relative;
}
.title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.custom-tooltip {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e8e9ea;
  background-color: #fff;
}
.lastPyeongRegion-title {
  width: 240px;
}
.tota1 {
  align-items: flex-start;
}
.no-data-message {
  width: 100%;
  padding: 40px 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.no-data-message p {
  font-size: 14px;
  color: #4a4f56;
}

/*custom tooltip */
.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.pyeongregion-title-box {
  display: flex;
  justify-content: space-between;
}
.calander-switch {
  width: 100per;
  display: flex;
  justify-content: right;
}
